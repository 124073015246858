import React from 'react'
import { Outlet,  } from "react-router-dom";
import '../css/hero.css'
export default function Hero() {
  return (
    <>
    <section className='hero'>
        <div className='container'>
            <div className='hero-text'>
                <h1>Your Trusted Partner for Cruise Line and vessels Operations in the Pacific.</h1>
                <p> At COMATUR, we specialize in streamlining cruise line and vessels operations across key Mexican Pacific ports, ensuring seamless schedules and top-notch services for every arrival. </p>
            </div>
        </div>
    </section>
    <Outlet />
    </>
  )
}
