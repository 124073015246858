import React, {useState} from 'react'
import '../css/contact.css'
//import axios from 'axios';
import Maps from './Maps';

export default function Contact() {

    const [nombre, setNombre] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [telefono, setTelefono] = useState('');
	const [email, setEmail] = useState('');
	const [mensaje, setMensaje] = useState('');
	
	const [alert, setAlert] = useState(false);

	const enviarCorreo = async () => {
      /*  try {
            //await axios.post('http://localhost:3001/enviar-correo', {
                await axios.post('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/enviar-correo', {
                nombre,
                empresa,
                telefono,
				servicio,
                email,
                mensaje,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
    
            // Limpiar campos después de enviar el correo
            setNombre('');
            setEmpresa('');
            setTelefono('');
			setServicio('');
            setEmail('');
            setMensaje('');
    
            setAlert(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }*/
            setAlert(true);
    };
  return (
    <>
    <section className='contact'>
        <div className='container'>
            <div className='contact-container'>
                <div className='contact-content'>
                    <div className='info-contact-box'>
                        <h2>Get In Touch</h2>
                        <p> Whether you have questions, need assistance, or just want to share your thoughts, feel free to reach out to us. We're here to help and ensure you have the best experience possible.</p>
                        <div className='contact-container-box'>
                            <div className='contact-content-box'>
                                <div className='contact-icon'><i class="fi fi-rs-phone-call"></i></div>
                                <div className='contact-box'>
                                    <div className='contact-title'>Phone</div>
                                    <div className='contact-info'>+52 0123 4567 89</div>
                                </div>
                            </div>
                            <div className='contact-content-box'>
                                <div className='contact-icon'><i class="fi fi-rs-envelope"></i></div>
                                <div className='contact-box'>
                                    <div className='contact-title'>Email</div>
                                    <div className='contact-info'>mgr@comatur.com</div>
                                </div>
                            </div>
                            <div className='contact-content-box'>
                                <div className='contact-icon'><i class="fi fi-rs-marker"></i></div>
                                <div className='contact-box'>
                                    <div className='contact-title'>Adress</div>
                                    <div className='contact-info'>Ignacio Allende No. 1025 </div>
                                </div>
                            </div>
                            <div className='contact-content-box'>
                                <div className='contact-icon'><i class="fi fi-rs-envelope"></i></div>
                                <div className='contact-box'>
                                    <div className='contact-title'>Adress</div>
                                    <div className='contact-info'>Ignacio Allende No. 1025 </div>
                                </div>
                            </div>                        
                        </div>
                    </div>
                    <div>
                    <Maps />
                    </div>
                </div>
                <div className='contact-content'>
                <div class="formulario">
                            <div>
                                <h2>Send us a message</h2>
                                <p>Use the form below to quickly send us a message. We will respond to your message as soon as possible.</p>
                            </div>
                            {alert &&
                                <div className='alertMessage'>
                                    <label>Your form has been successfully submitted!</label>
                                    <p>We will get in touch with you soon.</p>
                                </div>
                            }

                                <div class="form-group">
                                    <input
                                        type="text"
                                        placeholder="Your name"
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Company name (optional)"
                                        value={empresa}
                                        onChange={(e) => setEmpresa(e.target.value)}
                                    />
                                    
                                </div>
                                <div class="form-group">
                                    <input type='text'
                                        value={telefono}
                                        onChange={(e) => setTelefono(e.target.value)} 
                                        placeholder="Phone" />

                                    <input type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        />
                                </div>
                                
                                <div>
                                    <textarea 
                                        placeholder="How can we help you?"
                                        value={mensaje}
                                        onChange={(e) => setMensaje(e.target.value)}
                                        >
                                    </textarea>
                                </div>
                               
                                <div class="btn_enviar_formulario_contact">
                                    
                                    <button
                                        class="btn_enviar"                                 
                                        onClick={enviarCorreo}
                                        >
                                        Send message <i class="fi fi-rs-paper-plane-top"></i>
                                    </button>

                                </div>
                                
                        </div>
                </div>                
            </div>

        </div>
    </section>
    </>
  )
}
