import React from 'react'
import '../css/header.css'
import logo from '../assets/images/logo.png'
import { Outlet,  } from "react-router-dom";
export default function Header() {
  return (
    <>
    <section className='header'>
        <div className='container'>
            <div className='header-container'>
                <div className='header-content'>
                    <ul>
                        <li>Home</li>
                        <li>About us</li>
                        <li>Services</li>
                        <li>Company</li>
                    </ul>
                </div>
                <div className='header-content'>
                    <img src={logo} alt='comatur logo'/>
                </div>
                <div className='header-content'> 
                    <ul>
                        <li>Contact</li>
                        <li className='call-btn'>+52 0123 4567 89</li>                      
                    </ul>
                </div>
                <div className='header-content-burger'>
                    <i class="fi fi-rr-menu-burger"></i>
                </div>
            </div>
        </div>
    </section>
    < Outlet />
    </>
  )
}
