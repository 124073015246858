import React from 'react'

export const Maps = () => {
  return (
    <iframe
        title="Maps"
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14562.327701273762!2d-110.3172615!3d24.151316150000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86afd343b139ddd5%3A0x326eaf70aee954b3!2sBaja%20Ferries!5e0!3m2!1ses!2smx!4v1729715888183!5m2!1ses!2smx'
        width="100%"
        height="200px"
        frameBorder="0"
        allowfullscreen=""
        loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade"
        
    />
  )
}
export default Maps;
