import React from 'react';
import Slider from 'react-slick';
import '../css/carousel.css'
const Carrusel = () => {
  // Configuración del carrusel
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Deshabilita las flechas
  };

  const images = [
    "/images/logos_Disney Default.png",
    "/images/logos_Holland America Default.png",
    "/images/logos_Noruegian Default.png",

    "/images/logos_Oceania Default.png",
    "/images/logos_Ponan Default.png",
    "/images/logos_Princess Default.png",

    "/images/logos_Regent Deault.png",
    "/images/logos_Seabourn Default.png",
    "/images/logos_Windstar Default.png"                  
  ];


  return (
    <div className="carrusel-container carouselImg">
  
      <Slider {...settings}>
      {images.map((image, index) => (
        <img key={index} src={image} alt={`Slide ${index + 1}`} />
      ))}
      </Slider>
    </div>
  );
};

export default Carrusel;
