import React from 'react'
import { Outlet, Link } from "react-router-dom";
import logo from '../assets/images/logo-horizontal.png'
import '../css/footer.css'
export default function Footer() {
  return (
    <>
    <section class="footer">

        <div className='container'>
            <div className='footer_content'>
                <div className='footer_main_box'>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <img src={logo} alt='logo comatur'/>
                    </div>  
                    <p>We specialize in streamlining cruise line and vessels operations across key Mexican Pacific ports, ensuring seamless schedules and top-notch services for every arrival. </p>                
                    
                </div>
                <div className='footer_container'>
                    <div className='footer_box'>                        
                        <ul>
                            <li><h3>Company</h3></li>
                            <li><Link to="/">About Us</Link></li>
                            <li><Link to="/">Hitory</Link></li>
                            <li><Link to="/">Mission</Link></li>
                            <li><Link to="/">Vision</Link></li>
                                                   
                        </ul>
                    </div>
                    <div className='footer_box'>                    
                        <ul>
                            <li><h3>Services</h3></li>
                            <li><Link to="/">Port Operations</Link></li>
                            <li><Link to="/"> Cruise Line Coordination</Link></li>
                            <li><Link to="/">Support Services</Link></li>
                            <li><Link to="/">Custom Solutions</Link></li>                            
                        </ul>
                    </div>
                    <div className='footer_box'>                    
                        <ul>
                            <li><h3>Support</h3></li>                            
                            <li><Link to="/">Contact</Link></li>
                            <li><Link to="/">FAQ</Link></li>                           
                            <li><Link to="/">Privacy & Policy</Link></li>
                            <li><Link to="/">Help</Link></li>
                        </ul>
                    </div>
                </div>
                
            </div>
            
        </div>
        <div className='footer_copyright'>
            <div className='container'>
                <div>
                    <label>Copyright © COMATUR 2025. </label>
                    <label> All Right Reserved.</label>
                </div>
            
            </div>
        </div>

    </section>
    <Outlet />
    </>
  )
}
