import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Outlet,  } from "react-router-dom";
import '../css/home..css'
import Hero from '../components/Hero'
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import Carrusel from "../components/Carousel";
export default function Home() {

    const FAQ = ({ icon, question, answer }) => {
        const [showAnswer, setShowAnswer] = useState(false);
      
        const toggleAnswer = () => {
          setShowAnswer(!showAnswer);
        };
      
        return (
          <div className="faq-item">
            <div className="question" onClick={toggleAnswer}>
              <div className="icon_faq">{icon}</div>
              <div>{question}</div>
            </div>
            <CSSTransition in={showAnswer} timeout={300} classNames="answer" unmountOnExit>
              <div className="answer">{answer}</div>
            </CSSTransition>
          </div>
        );
      };

      const faqs = [
        {
          icon: <i class="fi fi-rr-angle-circle-down"></i>,
          question: "Port Operations",
          answer: "We ensure smooth operations from docking to departure, handling scheduling, berthing, and coordination with port authorities for a hassle-free experience.",
        },
        {
            icon: <i class="fi fi-rr-angle-circle-down"></i>,
            question: "Cruise Line Coordination",
            answer: "Our dedicated team works closely with cruise lines and vessels to manage schedules, handle customs and immigration, and ensure compliance with local regulations.",
        },
        {
          icon: <i class="fi fi-rr-angle-circle-down"></i>,
          question: "Support Services",
          answer: "From logistical support to emergency services, we provide end-to-end solutions to cater to every need of cruise ships and their crew.",
        },
        {
            icon: <i class="fi fi-rr-angle-circle-down"></i>,
            question: "Custom Solutions",
            answer: "At COMATUR, we tailor our services to meet the unique requirements of each client, offering flexible solutions for specific operational needs.",
        }
        
      ];

    return (
      <>
        <Hero />
        <Carrusel />
        {/*<section className='services'>
            <div className='container'>
                <div className='services-container'>
                    <div className="box left"></div>
                    <div className="box middle"></div>
                    <div className="box right"></div>
                </div>              
            </div>
        </section>*/}
        <section className='key-services'>
            <div className='container'>
                <div className='key-services-container'>
                    <div className='key-services-content-img'>

                    </div>
                    <div className='key-services-content-text'>
                        <h4>SERVICES</h4>
                        <h2>Partner for Cruise Line and vessels Operations</h2>
                        <p>We specialize in providing comprehensive services for cruise lines and vessel operations. From port coordination to tailored solutions, we ensure that every detail is managed for a smooth and hassle-free experience.</p>
                        
                        {faqs.map((faq, index) => (
                            <FAQ key={index} icon={faq.icon} question={faq.question} answer={faq.answer} />
                        ))}
                        <button>Get Stared</button>
                    </div>
                </div>
            </div>
        </section>

        <section className='banner-cta'>
            <div className='container'>
                <div className='banner-cta-container'>
                    <h2>Cruise Line</h2>
                    <p>We are very professional, reliable and experienced because we have been established 50 years ago and have been a pioneer as well as a pioneer for shipping services between countries which are now imitated but we still maintain our charteristics.</p>
                    <button>Get Stared</button>
                </div>
            </div>
        </section>
        <section className='best-services'>
            <div className='container'>
                <div className='best-services-container'>
                    <div className='best-services-content'>
                        <h2>We provide the best  <br /> and maximum service just for you</h2>
                        <div className='best-services-content-img'>
                            <div className='best-services-op1'><button>Read More <i class="fi fi-rr-arrow-right"></i></button></div>
                            <div className='best-services-op2'><button>Read More <i class="fi fi-rr-arrow-right"></i></button></div>
                            <div className='best-services-op3'><button>Read More <i class="fi fi-rr-arrow-right"></i></button></div>
                        </div>
                    </div>
                    <div className='best-services-content-text'>
                        <h2>We provide the best service</h2>
                        <p>Service is the most important thing that we will provide as much as possible and as best as possible that we can and that is our characteritic and we still maintain it until now.</p>
                    </div>
                </div>
            </div>
        </section>
        <section>

        </section>
        <Contact />
        <Footer />
        <Outlet />
      </>
          
    )
  }
